var streamData;
var positionTop = $(window).scrollTop();
$(document).ready(function(){
  getToday();
  getHistoriografis();
  showPodcast(positionTop);
  getHomeRecomendation();
  getTrivia();
})
$(window).on('scroll', function(){
  positionTop = $(window).scrollTop();
  showPodcast(positionTop);
})
$(window).on('load', function(){
  if($("#Leaderboard").height() == 0){
    $(".home-lapsus-container").css("margin-top","20px")
  }
})
function showPodcast(positionTop){
  var totalScale = $('.home-lapsus-container').position().top
  if(positionTop > totalScale){
    $('#podcastHomeSection').show()
  }else{
    $('#podcastHomeSection').hide()
  }
}
function getToday(){
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agt", "Sep", "Okt", "Nov", "Des"];
  var d = new Date();
  var dd = String(d.getDate()).padStart(2, '0');
  $('.today-history-date').html(dd+" "+monthNames[d.getMonth()])
}
function getTrivia(){
  actionWebApiRestrict('/v1/trivia/quiz', {}, 'GET').done(function(json) {
    if (json.status == 600) {
      let streamQuiz = json.data.quiz;
      var append = '<div class="home-trivia-container">'
      append += '<img class="lazy-trivia" data-original="'+streamQuiz.cover_quiz_web+'" alt="trivia">'
      append += '<span><a href="/trivia">Mulai Kuis</a></span>'
      append += '</div>'
      $('#trivia-link').html(append)
      $('.lazy-trivia').lazyload()
    }
  })
}
function getHistoriografis(){
  actionWebApiRestrict('/v1/stream/article/historiografis', {}, 'GET').done(function(json) {
    if (json.status == 600) {
      streamData = json.data;
      let imgData = streamData.content;
      let imgDataSubstring = imgData.substring(imgData.indexOf('http'), imgData.lastIndexOf('"'));
      let imgDataSplit = imgDataSubstring.split('"')

      var append = '<div class="home-historiografis-detail">'
      append += '<a href="'+streamData.url+'" onclick="panelTrackingPushSection(\'Historiografis\',\''+streamData.url+'\',\'Tengah Dibawah Pilihan Redaksi\',\''+streamData.url+'\')"><img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" class="lazy-historiografis" data-original="'+imgDataSplit[0]+'" alt="cover historiografis">'
      append +=   '<span class="home-historiografis-link-container">'
      append +=     '<span class="btn-historiagrafis">LIHAT SEUTUHNYA</span>'
      append +=   '</span>'
      append += '</a>'
      append += '</div>'
      $('#historiografis-container').html(append)
      $('.lazy-historiografis').lazyload()
    }
  })
}

var panelTrackingPushSection = function(item, target, position, title) {
  dataLayer.push({
    'event': 'paneltracking',
    'panelname': item,
    'panelposition': position,
    'panelpage_url': target,
    'panelpage_title': title
  });
}

function getHomeRecomendation(){
  actionWebApiRestrict('/v1/stream/article/most-clicked', {'limit':5}, 'GET').done(function(json) {
    if (json.status == 600) {
      stream = json.data;
      keynumber = 1;
      var appendText = '';
      $.each(stream, function(key, value) {
        appendText += drawHomeTrendingNow(value, keynumber,'Rekomendasi', 'Home Page');
        keynumber ++;
      })
      $('#rekomendasi-list').append(appendText)
    }
  })
}